<template>
  <div>
    <c-table
      ref="table"
      title="월별 일상점검결과"
      :columns="grid.columns"
      :merge="grid.merge"
      :gridHeight="grid.height"
      :data="filteringData"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      @headLinkClick="headLinkClick"
    >
      <template slot="customFilter">
        <c-radio
          :disabled="disabled"
          :editable="editable"
          :comboItems="filterItems"
          label=""
          name="filter"
          v-model="filter"
        />
      </template>
      <template slot="prefixContent">
        <font v-if="!preWorkCheck.plantCd || !preWorkCheck.sopPreWorkCheckClassCd || !preWorkCheck.deptCd || !preWorkCheck.yearmonth" color="#C10015">
          {{'※ 사업장, 점검구분, 점검주관부서 및 점검년월을 선택하세요.'}}
        </font>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
export default {
  name: 'preWorkCheckTargetResult',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          sopPreWorkCheckId: '',
        }
      },
    },
    preWorkCheck: {
      type: Object,
      default: function() {
        return {
          sopPreWorkCheckId: '',  // 작업 전 안전점검 일련번호
          plantCd: null,  // 사업장 코드
          pwcPreWorkCheckStepCd: '',  // 작업 전 안전점검 진행상태
          sopPreWorkCheckClassCd: '',  // 작업 전 안전점검 구분 코드
          apprCycle: '', // 점검결재 주기
          checkName: '',  // 점검명
          deptCd: '',  // 주관부서
          deptName: '',  // 주관부서
          managerId: '',  // 관리감독자 일련번호
          yearmonth: '',  // 년월
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          targets: [],
          monthResults: [],
          approvalStatusCd: '', // 결재관련 결재상태
          sysApprovalRequestId: '', // 결재관련 결재요청코드
        }
      }
    },
    grid: {
      type: Object,
      default: function() {
        return {
          merge: [
            { index: 0, colName: 'sopPreWorkCheckClassCd' },
            { index: 1, colName: 'checkItemName' },
          ],
          columns: [],
          defaultColumns: [
            {
              fix: true,
              name: 'sopPreWorkCheckClassName',
              field: 'sopPreWorkCheckClassName',
              label: '점검구분',
              align: 'center',
              sortable: true,
              style: 'width:120px',
            },
            {
              fix: true,
              name: 'checkItemName',
              field: 'checkItemName',
              label: '점검항목명',
              align: 'left',
              sortable: true,
              style: 'width:150px',
            },
            {
              fix: true,
              name: 'checkItemSubName',
              field: 'checkItemSubName',
              label: '점검세부항목명',
              align: 'left',
              sortable: true,
              style: 'width:300px',
            },
          ],
          height: '500px'
        }
      }
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      filter: 'A',
      filterItems: [
        { code: 'A', codeName: '전체보기', },
        { code: 'M', codeName: '할당된 항목 보기', },
      ],
      editable: true,
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    filteringData() {
      let items = [];
      if (this.preWorkCheck.monthResults && this.preWorkCheck.monthResults.length > 0) {
        if (this.filter === 'M') {
          // 나에게 할당된 점검항목 filtering
          items = this.$_.filter(this.preWorkCheck.monthResults, (monthResult) => {
            return Boolean(monthResult.userId) && monthResult.userId.indexOf(this.$store.getters.user.userId) > -1
          })
        } else {
          // 전체보기
          items = this.preWorkCheck.monthResults;
        }
      }
      return items;
    }
  },
  watch: {
    height() {
      let height = this.height - 250;
      if (height < 550) {
        height = 550;
      }
      this.grid.height = String(height) + 'px';
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    headLinkClick(props, col) {
      if (this.isOld) {
        this.popupOptions.title = '일별 일상점검 결과 상세 (' + this.preWorkCheck.yearmonth + '-' + col.label + ')'
        this.popupOptions.target = () => import(`${"./preWorkCheckTargetDayResult.vue"}`);
        this.popupOptions.param = {
          sopPreWorkCheckId: this.preWorkCheck.sopPreWorkCheckId,
          yearmonth: this.preWorkCheck.yearmonth,
          date: this.preWorkCheck.yearmonth + '-' + col.label,
          disabled: this.disabled,
          apprCycle: this.preWorkCheck.apprCycle,
        };
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeResultPopup;
      } else {
        // 해당 버튼을 누른경우는 필수요소들이 다 있는 경우임으로 더 이상의 유효성평가는 진행 X
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '입력한 정보를 저장한 후 진행가능합니다.\n저장하시겠습니까?', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$emit('save')
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    closeResultPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit('getDetail')
    },
  }
};
</script>
